import { inject, Injectable } from "@angular/core";
import {
	Auth,
	GoogleAuthProvider,
	signInWithPopup,
	UserCredential,
} from "@angular/fire/auth";
import { ISocialAuthProvider } from "@sportyano/core/models/authentications/social-login-data.model";
import { from, Observable } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class GoogleAuthService implements ISocialAuthProvider {
	private _fireBaseAuth = inject(Auth);

	signIn(): Observable<UserCredential> {
		return from(
			signInWithPopup(this._fireBaseAuth, new GoogleAuthProvider())
		);
	}
}
